@font-face {
  font-family: 'AvenirNextLTPro';
  src: local('AvenirNextLTPro'), url(./fonts/AvenirNextLTPro-Regular.otf) format('opentype');
}

@font-face {
  font-family: 'AvenirNextLTPro';
  font-weight: 700;
  src: local('AvenirNextLTPro'), url(./fonts/AvenirNextLTPro-Bold.otf) format('opentype');
}

.App {
  text-align: center;
}

body{
  font-family: AvenirNextLTPro, Arial, Helvetica, sans-serif !important;
}






/* Navbar */
.navbar .logo{
  width: 4vw;
}
.navbar{
  background-color: black;
}

.navbar .navbar-nav .nav-link{
  color: white !important;
  width: 10vw;
  font-size: 1.3rem;
}

.navbar .navbar-nav .nav-link:hover{
  color: #B89117 !important;
}

.navbar-light .navbar-text{
  color: #FFF !important;
}

.bag-counter{
  position: relative;
  /* margin-top: -30px; */
  left: -21px;
  background-color: red;
  width: 25px;
  text-align: center;
  border-radius: 20px;
}

.search-input:active, .search-input:focus-visible, .search-input>div:hover, .search-input>div{
  border: none;
  box-shadow: none;
}

.search-input{
  width: 300px;
  margin-right: 7px;
  color: black !important;
  z-index: 999;
}



/* Header Bar */
.header-page{
  background-color: #B89117;
  /* display: inline-block; */
  width: 100%;
  text-align: right;
  vertical-align: middle;
  padding-top: 0.2rem;
}

.contacto {
  color: #FFF;
  font-size: 1.5rem;
}

.header-page > div, .contacto > p{
  display: inline-block;
  padding-right: 2rem;
}

.contacto > p{
  margin-bottom: 0.5rem;
}

.icon{
  width: 20px;
}

.details-wrapper {
  padding-top: 100px;
}

/*  Details Page*/

.more-wrapper .detail{
  color: #868686;
}

.bar{
  min-width: 100px;
  width: 250px;
  margin-left: auto;
  margin-right: auto;
}

.bar100{
  min-width: 50px;
  width: 100px;
}

.bar, .bar100{
  min-height: 6px;
  margin-top: -1px;
  margin-bottom: 2vh;
  background-color: #B89117;
}

.more-wrapper{
  margin-top: 3vh;
  border-top: 1px #868484 solid;
  font-size: 1.2rem;
}

.detail-section{
  color:#868484;
  text-transform: capitalize;
  font-size: 1.5rem;
}

.about-section{
  color:#868484;
  text-align: center;
  font-size: 1.8rem;
}

.about-line {
  border-bottom: #B89117 8px solid;
  width: 80px;
  margin-right: auto;
  margin-left: auto;
}

.txt-black {
  color: black;
  font-weight: 700;
}

.txt-gray {
  color: #868484;
  font-size: 1.3rem;
}

.text-​justify{
  text-align: justify;
}

.text-center{
  text-align: center;
}

.price{
  font-size: 1.5rem;
  color: #B89117;
}










/* GMAPS RESPONSIVE*/

#mapwrapper{
  background-color: rgb(60, 60, 61);
}
.map-responsive{
  overflow:hidden;
  padding-bottom: 25%;
  position:relative;
  height:0;
}
.map-responsive iframe{
  left:0;
  top:0;
  height:50vh;
  width:100%;
  position:absolute;
}

iframe{
  z-index: 1;
  opacity: 0.5;
  background-color: blue;
}