.slider-t3, .slider-t1, .slider-t2, .slider-t4, .slider-t0{
    position: relative;
    top: 6vw;
    font-size: 1.5rem;
    color: white;
    z-index: 100;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    padding: 5px 25px;
}

.slider-t0, .slider-t3, .slider-t2, .slider-t4{
    text-shadow: 2px 2px 6px black;
}
.slider-t0{
    font-weight: 900;
    font-size: 2rem;
}
.slider-t2{
    font-weight: 700;
    font-size: 2rem;
}
.slider-t2{
    font-size: 1.5rem;
}

.slider-t4{
    font-size: 1.1rem;
}

.slider-t1 a{
    color: white;
}
.slider-t1{
    font-weight: 600;
    background-color: #D89117;
    border-radius: 40px;
}

.slide1, .slide2{
    height: 400px;
    background-size: cover;
}

.slide1{
    background-image: url('../../assets/slider0.png');
}

.slide2{
    background-image: url('../../assets/slider1.jpg');
}