/*POWERED BY*/
	.powered {
        margin-top: 16px;
	}
	
	.powered a {		
    position: absolute;
    right: 0;
    width: 129px;
    min-height: 28px;
    background-color: red;
    padding: 15px;
    padding-left: 129px;
    background: url('../../assets/footer01.png') no-repeat;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
	}

	.powered a:hover {
    background: url('../../assets/footer02.png') no-repeat;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;

}

/* Footer */
.footer{
    color: #ffffff;
    font-size: 1.2rem;
    /* border-top: 1px #868484 solid; */
    background-color: #B89117;
    line-height: 4rem;
    width: 100%;
    text-align: center;
}