.res{
    background-image: url('../../assets/res.png');
}
.puerco{
    background-image: url('../../assets/puerco.png');
}
.pollo{
    background-image: url('../../assets/pollo.png');
}
.mariscos{
    background-image: url('../../assets/mariscos.png');
}

.header-section{
    width: 100%;
    background-size: cover;
    text-align: center;
    color: #FFF;
    font-size: 2rem;
    font-weight: 700;
    line-height: 4rem;
}

.product-wrapper{
    margin-top: 2rem;
    margin-bottom: 2rem;
    display: block;
    width: 25%;
    text-align: center;
}
.product-wrapper > a > img{
    height: 133px;
    width: auto;
    overflow-y: hidden;
}

.product-wrapper > img{
    display: block;
    /* width: 75%; */
    margin-left: auto;
    margin-right: auto;
}

.product-name{
    width: 100%;
    height: 2.7rem;
    font-weight: 900;
    color: #000;
}
.product-name:hover, .product-wrapper a:hover{
    color:#B89117;
    text-decoration: none !important;
}
.product-price{
    width: 100%;
    color: #B89117;
}