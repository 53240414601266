.shop-button{
    display: inline-block;
}

.less-btn{
    border: 1px solid #DADADA;
}

.plus-btn{
    margin-right: 10px;
    border: 1px solid #DADADA;
}

.inpute{
    width: 50px;
    text-align: center;
    border: 1px solid #DADADA;
}

.add-btn{
    background-color: #B89117;
    color: white;
    border: none;
    padding: 2px 8px;
}